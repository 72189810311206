<template>
  <div class="form">
    <!-- 组1 -->
    <div class="weui-cells">
      <ainput
        type="text"
        text="提报人"
        :model.sync="name"
        value=""
        placeholder=""
        disabled
      >
      </ainput>
    </div>
    <!-- 组2 -->
    <div class="weui-cells">
      <ainput
        type="number"
        text="中建员工总数"
        :model.sync="name"
        value=""
        placeholder="0"
        disabled
      >
       
      </ainput>
      <ainput
        type="text"
        text="国内休假员工"
        :model.sync="name"
        value="0"
        placeholder="请输入"
      >
        
      </ainput>
    </div>
    <!-- 组3 -->
    <div class="weui-cells__title">在场人员统计</div>
    <div class="weui-cells">
      <ainput
        type="text"
        text="中方管理人员"
        :model.sync="name"
        value="0"
        placeholder="请输入"
        disabled
      >
        
      </ainput>
      <ainput
        type="text"
        text="中方劳务人员"
        :model.sync="name"
        value="0"
        placeholder="请输入"
        disabled
      >
        
      </ainput>
    </div>
    <!-- 组4 -->
    <div class="weui-cells__title">隔离人员</div>
    <div class="weui-cells">
      <ainput
        type="text"
        text="隔离人员"
        :model.sync="name"
        value="0"
        placeholder="请输入"
        disabled
      >
        
      </ainput>
    </div>
    <div class="btn-area">
      <button class="weui-btn weui-btn_primary" @click="submit">修改</button>
    </div>
  </div>
</template>
<script>
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
export default {
  data() {
    return {
      name: "",
      sex: [
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 0,
        },
      ],
    };
  },
  components: {
    ainput,
    apicker,
  },
  mounted() {},
  methods: {
    submit() {
      console.log(this.name);
    },
  },
};
</script>

<style >
.change {
  width: .50rem;
  color: #999;
  font-size: .14rem;
  flex-shrink: 0;
}
</style>